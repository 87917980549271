import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { SampleComponent } from './sample.component';
import { HomeComponent } from './home.component';
import { ProductsComponent } from './products/products.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { ChallanHistoryComponent } from './challan-history/challan-history.component';
import { OrderPlaceComponent } from './order-place/order-place.component';
import { AuthGuard } from 'app/auth/helpers';
import { CoreTouchspinModule } from '@core/components/core-touchspin/core-touchspin.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AutocompleteLibModule} from 'angular-ng-autocomplete';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';  

const routes = [
  {
    path: 'home',
    component: HomeComponent,
    data: { animation: 'home' }
  },
  {
    path: 'help',
    component: SampleComponent,
    canActivate: [AuthGuard],
    data: { animation: 'sample' }
  },
  {
    path: 'order-place',
    component: OrderPlaceComponent,
    canActivate: [AuthGuard],
    data: { animation: 'home' }
  },
  {
    path: 'products',
    component: ProductsComponent,
   // canActivate: [AuthGuard],
    data: { animation: 'home' }
  },
  {
    path: 'order-history',
    component: OrderHistoryComponent,
    canActivate: [AuthGuard],
    data: { animation: 'home' }
  },
  {
    path: 'challan-history',
    component: ChallanHistoryComponent,
    canActivate: [AuthGuard],
    data: { animation: 'home' }
  }
];

@NgModule({
  declarations: [SampleComponent, HomeComponent, ProductsComponent, OrderHistoryComponent, ChallanHistoryComponent, OrderPlaceComponent],
  imports: [RouterModule.forRoot(routes, { useHash: true }), ContentHeaderModule, TranslateModule, CoreCommonModule,CoreTouchspinModule,PdfViewerModule,AutocompleteLibModule,ToastrModule,NgbModule],
  exports: [SampleComponent, HomeComponent, RouterModule]
})
export class SampleModule { }
