<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <!-- content-header component -->
        <app-content-header [contentHeader]="contentHeader"></app-content-header>

        <!-- Basic Alerts start -->
        <section id="sample-page">
            <div class="row">
                <div class="col-12">
                    <div class="card text-center p-2">

                        <div class="pdf-viewer">
                            <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false" style="margin:auto;width: 100%; height: 600px;"></pdf-viewer>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <!-- Basic Alerts end -->
    </div>
</div>