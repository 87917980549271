<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
    <!-- Navbar brand -->
    <ul class="nav navbar-nav flex-row ">
        <li class="nav-item">
            <a class="navbar-brand" [routerLink]="['/products']">
                <h2 class="brand-text mb-0"> <img src="assets/images/logo/logo-text.svg" style="width: 150px;height:59px;" alt="brand-logo" /></h2>
            </a>
        </li>
    </ul>
    <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
    <div class="bookmark-wrapper d-flex align-items-center">
        <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
        <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
            <li class="nav-item">
                <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
                    <span [data-feather]="'menu'" [class]="'ficon'"></span>
                </a>
            </li>
        </ul>
        <!--/ Menu Toggler -->

        <!-- Toggle skin -->
        <li class="nav-item d-none d-lg-block">
            <a type="button" class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
                <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
            </a>
        </li>
        <!--/ Toggle skin -->
    </div>

    <ul class="nav navbar-nav align-items-center ml-auto">
        <!-- Toggle skin -->
        <li class="nav-item d-block d-lg-none">
            <a type="button" class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
                <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
            </a>
        </li>
        <!--/ Toggle skin -->

        <!-- ? Language selection | Uncomment if needed-->
        <li ngbDropdown class="nav-item dropdown dropdown-language">
            <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
                <i class="flag-icon">{{ languageOptions[_translateService.currentLang].flag }}</i> -
                <span class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span>
                <i [data-feather]="'chevron-down'"></i>
            </a>
            <div ngbDropdownMenu aria-labelledby="dropdown-flag">
                <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
                    {{ languageOptions[lang].title }}
                </a>
            </div>
        </li>
        <!--/ Language selection -->

        <!-- User Dropdown -->
        <li ngbDropdown class="nav-item dropdown-user" *ngIf="currentUser">
            <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
                <div class="user-nav d-sm-flex d-none">
                    <span class="user-name font-weight-bolder">{{ currentUser.dealer.name }}</span><span class="user-status">{{ currentUser.manufacturer.name }}</span>
                </div>
            </a>

        </li>
        <!--/ User Dropdown -->
    </ul>
</div>