<!-- app-content-header start -->
<ng-container *ngIf="!contentHeader">
    <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
                <div class="col-12 d-flex">
                    <h2 class="content-header-title float-left mb-0">
                        {{ contentHeader.headerTitle }}
                    </h2>
                    <!-- app-breadcrumb component -->
                    <app-breadcrumb [breadcrumb]="contentHeader.breadcrumb"></app-breadcrumb>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<!-- app-content-header end -->