export const locale = {
  lang: 'de',
  data: {
    MENU: {
      PLACE_ORDER: 'ऑर्डर दो',
      PRODUCTS: 'उत्पादों',
      ORDER_HISTORY: 'ऑर्डर इतिहास',
      DELIVERY_CHALLAN: 'डिलीवरी चालान',
      HELP: 'मदद'
    }
  }
}
