import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrdersHistoryService {

  public key = localStorage.getItem('currentToken');

  constructor(private _http: HttpClient) { }
  getData(key:any = this.key): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}order-history`, { key })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  deleteOrder(id,key:any = this.key): Observable<any>
  {
    return this._http
    .post(`${environment.apiUrl}delete-order-customer`, { key,id })
    .pipe(
      map(data => {
        return data;
      })
    );
  }
}
