export const locale = {
  lang: 'en',
  data: {

    TITLE: 'Digital Order Book',
    SORT_INFO: 'The digital order book is a web application that allows manufacturer or dealers to accept and manage orders that placed via website without using mobile app. Online Order Management Customer Order Management On Single Click.',
    WELCOME: 'Welcome To',
    PLEASE_WAIT: 'Please wait we are fetching your details...',
    UNAUTHORIZED_ACCESS: 'Unauthorized access. Please contact your supplier.',
    PLEASE_WAIT_LOADING: 'Please wait we are fetching details...',
    NO_DATA_FOUND: 'No Data Found!',
    REMOVE: 'Remove',
    REMARK_IF: 'Remark If Any',
    REMARK: ' Remark',
    TOTAL_QTY: 'Total Qty',
    SUBMIT: 'Submit',
    SEARCH_PRODUCT: 'Search Product, Min. 3 Characters',
    VIEW_DETAIL: 'View Detail',
    ORDER_STATUS_PENDING: 'Pending',
    ORDER_STATUS_PROCESSING: 'Processing',
    ORDER_STATUS_HALF_DISPATCH: 'Half Dispatch',
    ORDER_STATUS_FULL_DISPATCH: 'Full Dispatch',
    ORDER_STATUS_CANCELLED: 'Cancelled',
    DATE: 'Date',
    ORDER_DATE: 'Order Date',
    ORDER_DETAILS: 'Order Details',
    CHALLAN_ID: 'Challan ID',
    AMOUNT: 'Amount',
    CHALLAN_DETAILS: 'Challan Details',
    LOADING: 'Loading...',
    PLEASE_WAIT_SMALL: 'Please Wait...',
    DESCRIPTION: 'Description',
    RATE: 'Rate',
    QTY: 'Qty',
    TOTAL: 'Total',
    TRANSPORT_DETAILS: 'Transport Details',
    CLOSE: 'Close',
    LR_NO: 'LR No',
    TRANSPORT_NAME: 'Transport Name',
    VEHICLE_NO: 'Vehicle No',
    MOBILE: 'Mobile',
    ORDER_LIST_AVAILABLE_ONCE_YOU: 'Order list available once you add product to order list',
    SUB_TOTAL: 'Sub Total',
    DISCOUNT: 'Discount',
    ROUND_OFF: 'Round Off',
    GRAND_TOTAL: 'Grand Total',
    CLEAR_ALL: 'Clear All',
    PLACE_ORDER: 'Click To Place Order',
    PHONE_NUMBER: 'Phone Number',
    PHONE_NUMBER_ERROR: 'Phone Number is required',
    PHONE_NUMBER_VALID_ERROR: 'Please Provide Valid Mobile No.',
    ENTERPRISE_NAME: 'Enterprise Name',
    ENTERPRISE_NAME_ERROR: 'Enterprise Name is required',
    SELECT_STATE: 'Select State',
    SELECT_STATE_ERROR: 'State is required',
    SELECT_CITY: 'Select City',
    SELECT_CITY_ERROR: 'City is required',
    DISPATCH: 'Dispatch',
    REMAINING: 'Remaining',
    
  }
}
