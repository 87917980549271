import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(key: string, type: string = 'customer') {
    if (type == "customer") {
      return this._http
        .post<any>(`${environment.apiUrl}get-dealer-details`, { key })
        .pipe(
          map(user => {
            user.token = this.getToken();
            // login successful if there's a jwt token in the response
            if (user && user.dealer) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('currentUser', JSON.stringify(user));

              localStorage.setItem('oldCurrentUser', JSON.stringify(user));
              // notify
              this.currentUserSubject.next(user);
            }
            return user;
          })
        );
    }
    else {
      return this._http
        .post<any>(`${environment.apiUrl}get-supplier-details`, { key })
        .pipe(
          map(user => {
            return user;
          })
        );
    }
  }

  autologin()
  {
   
    localStorage.setItem('currentUser', localStorage.getItem('oldCurrentUser'));
    this.currentUserSubject.next(JSON.parse(localStorage.getItem('oldCurrentUser')));
    return JSON.parse(localStorage.getItem('oldCurrentUser'));
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('cart');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentToken');
    // notify
    this.currentUserSubject.next(null);
  }

  public saveToken(token: string): void {
    localStorage.setItem('currentToken', JSON.stringify(token));
  }

  public getToken(): string | null {
    return localStorage.getItem('currentToken');
  }

  public getcurrentUser(): string | null {
    return localStorage.getItem('currentUser');
  }

  public getOldCurrentUser(): string | null {
    return localStorage.getItem('oldCurrentUser');
  }

  getState(): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}get-state-dropdown`)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getCity(stateId: any): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}get-cities-dropdown/` + stateId)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(enterprise_name: any, phone: any, state_id: any, city_id: any, key: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}save-customer`, { enterprise_name, phone, state_id, city_id, key })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  verifyotp(mobile: any, otp: any, type: string = "guest"): Observable<any> {

    return this._http
      .post<any>(`${environment.apiUrl}verify-otp`, { mobile, otp, type })
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.manufacturer) {
            user.token = this.saveToken(user.tokan);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            // notify
            this.currentUserSubject.next(user);
          }
          return user;
        })
      );

    // return this._http
    //   .post(`${environment.apiUrl}verify-otp`, { mobile, otp, type })
    //   .pipe(
    //     map(data => {
    //       return data;
    //     })
    //   );
  }

  resendotp(mobile): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}resend-otp`, { mobile })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
