import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CoreConfigService } from '@core/services/config.service';
import { Subject } from 'rxjs';
import { locale as en } from '../i18n/en'
import { locale as fr } from '../i18n/fr'
import { locale as de } from '../i18n/de'
import { CoreTranslationService } from '@core/services/translation.service'
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';
import { PlaceOrderService } from 'app/main/sample/order-place/place-order.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-order-place',
  templateUrl: './order-place.component.html',
  styleUrls: ['./order-place.component.scss']
})
export class OrderPlaceComponent implements OnInit {
  @ViewChild('auto') auto: any;

  public keyword = 'name';
  public keywordProduct = 'full_name';
  public historyHeading: string = 'Recently selected';
  public isLoading: boolean = false;
  public isLoadingProduct: boolean = false;
  public allProduct: any;
  public order_details: any = [];
  public total_qty: any = 0;

  public newForm: FormGroup;
  public loading: boolean = false;

  public coreConfig: any;
  private _unsubscribeAll: Subject<any>;
  public contentHeader: object

  constructor(private _toastrService: ToastrService, private placeOrderService: PlaceOrderService, private _router: Router, private _authenticationService: AuthenticationService, private _coreTranslationService: CoreTranslationService, private _coreConfigService: CoreConfigService, private _formBuilder: FormBuilder) {
    this._unsubscribeAll = new Subject();
    this._coreTranslationService.translate(en, fr, de)

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        menu: {
          hidden: false
        }
      }
    };
  }

  ngOnInit(): void {
    let newPro: any = [];
    newPro = JSON.parse(localStorage.getItem('cart'));

    if (newPro !== null && newPro.length > 0) {
      for (var i = 0; i < newPro.length; i++) {
        this.total_qty += newPro[i].qty;
      }
    }
    if (newPro !== null) {
      this.order_details = newPro;
    }

    this.newForm = this._formBuilder.group({
      remarks: [''],
    });
  }

  get f() {
    return this.newForm.controls;
  }

  onChangeSearchProduct(value: string) {
    this.isLoadingProduct = true
    this.placeOrderService.getData(value).subscribe(
      data => {
        this.isLoadingProduct = false
        if (data.status) {
          this.allProduct = data.product_data;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/home']);
        }
      }
    );
  }
  selectEventProduct(item: any) {
    if (this.order_details.length > 0 && this.order_details.some((detail: { id: any; }) => detail.id === item.id)) {
      this._toastrService.clear();
      this._toastrService.warning('Product already added', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
    } else {
      item.qty = item.min_order_qty;
      item.total = item.sales_price;
      if (item.discount > 0) {
        item.dispaly_price = item.sales_price - ((item.sales_price * item.discount) / 100);
      }
      this.total_qty += item.min_order_qty;
      this.order_details.push(item);
      this.order_details.reverse();
      localStorage.setItem('cart', JSON.stringify(this.order_details));
    }
    this.clear();
  }

  onFocusedProduct(e: any) {
    // do something when input is focused
  }
  countChange(qty: any, product_id: any) {
    this.total_qty = 0;
    for (var i = 0; i < this.order_details.length; i++) {
      if (this.order_details[i].id === product_id) {
        this.order_details[i].qty = parseFloat(qty);
        this.order_details[i].total = this.order_details[i].sales_price * parseFloat(qty);
      }
      this.total_qty += this.order_details[i].qty;
    }
    localStorage.setItem('cart', JSON.stringify(this.order_details));

  }
  productDelete(product_id: any) {
    for (var i = 0; i < this.order_details.length; i++) {
      if (this.order_details[i].id == product_id) {
        this.total_qty -= this.order_details[i].qty;
        this.order_details.splice(i, 1);
      }
    }
    localStorage.setItem('cart', JSON.stringify(this.order_details));
  }

  clear(): void {
    this.auto.clear();
  }

  storeDealerOrder() {
    this.loading = true;
    this.placeOrderService.storeData(this.order_details, this.f.remarks.value).subscribe(
      data => {
        if (data.status) {
          this.loading = false;
          this._toastrService.clear();
          this._toastrService.success(data.message, data.data, { toastClass: 'toast ngx-toastr', closeButton: true });
          localStorage.removeItem('cart');
          this.order_details = [];
          const currentUser = JSON.parse(localStorage.getItem('currentUser'));
          if (currentUser.role == 'User') {
            this._router.navigate(['/products']);
          }
          else {
            this._router.navigate(['/order-history']);
          }
        }
        else {
          this._toastrService.clear();
          this._toastrService.error(data.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        this.loading = false;
        if (err.error.error == 'Unauthorised Access') {
          this._authenticationService.logout();
          this._router.navigate(['/home']);
        }
        this._toastrService.clear();
        this._toastrService.error(err.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }
  removeAll() {
    localStorage.removeItem('cart');
    this.order_details = [];
  }
}
