import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
 
  {
    id: 'products',
    title: 'Products',
    translate: 'MENU.PRODUCTS',
    type: 'item',
    icon: 'box',
    url: 'products',
    role: ['Client','User','Guest']
  },
  {
    id: 'order-place',
    title: 'Place Order',
    translate: 'MENU.PLACE_ORDER',
    type: 'item',
    icon: 'shopping-cart',
    url: 'order-place',
    role: ['Client','User']
  },
  {
    id: 'order-history',
    title: 'Order History',
    translate: 'MENU.ORDER_HISTORY',
    type: 'item',
    icon: 'list',
    url: 'order-history',
    role: ['Client']
  }, 
  {
    id: 'delivery-challans',
    title: 'Delivery Challans',
    translate: 'MENU.DELIVERY_CHALLAN',
    type: 'item',
    icon: 'list',
    url: 'challan-history',
    role: ['Client']
  }, 
  {
    id: 'help',
    title: 'Help',
    translate: 'MENU.HELP',
    type: 'item',
    icon: 'info',
    url: 'help',
    role: ['Client']
  }
]
