export const locale = {
  lang: 'fr',
  data: {
    MENU: {
      PLACE_ORDER: 'ઓર્ડર કરો',
      PRODUCTS: 'ઉત્પાદનો',
      ORDER_HISTORY: 'ઓર્ડર ઇતિહાસ',
      DELIVERY_CHALLAN: 'ડિલિવરી ચલણ',
      HELP: 'મદદ'
    }
  }
}
