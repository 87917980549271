<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <!-- content-header component -->
        <app-content-header [contentHeader]="contentHeader"></app-content-header>


        <ngb-accordion [closeOthers]="true" activeIds="accordion-0" *ngIf="productsList?.length != 0">
            <ngb-panel id="accordion-{{ cndx }}" *ngFor="let products of productsList; let cndx = index">
                <ng-template ngbPanelTitle>
                    <div class="row">
                        <div class="col-11">
                            <span class="lead collapse-title" *ngIf="products.category_name != ''"> {{ products.category_name }} </span>
                            <span class="lead collapse-title" *ngIf="!products.category_name"> General </span>
                        </div>
                        <div class="col-1"><i data-feather="chevron-down"></i></div>
                    </div>

                </ng-template>
                <ng-template ngbPanelContent>
                    <section class="row match-height">
                        <!-- Ecommerce Item -->
                        <div class="col-6 col-md-3 col-lg-3" style="padding: 0px 10px;margin-bottom: 20px;" *ngFor="let product of products.products; let ndx = index">
                            <div class="card pt-1 pb-1">
                                <div class="item-img text-center" *ngIf="product.productImage?.length != 0" (click)="modalOpen(modalBasic,cndx,ndx)">

                                    <img *ngIf="product.productImage?.length != 0" src="{{product.productImage[0].product_photo}}" alt="Digital Order Book" style="width: 150px;">
                                    <img *ngIf="product.productImage?.length == 0" src="assets/images/no-img.png " alt="Digital Order Book " style="width: 150px;opacity: 0.2; ">

                                </div>
                                <div class="card-body " style="padding: 0px 10px;" (click)="modalOpen(modalBasic,cndx,ndx)">

                                    <span *ngIf="product.category_name != ''">{{product.category_name}}<br/></span>
                                    <h4>{{product.product_code}} {{product.product_name}}</h4>
                                    <span *ngIf=" product.size_title != '' || product.color_title != '' ">
                                        <span *ngIf="product.size_title != '' ">Size : {{product.size_title}}</span>
                                    <span *ngIf="product.color_title != '' ">, &nbsp; Color : {{product.color_title}}</span>
                                    <br/>
                                    </span>

                                    <span *ngIf="product.packing_qty> 0 || product.master_packing_qty > 0">
                                        <span *ngIf="product.packing_qty > 0">Packing Qty : {{product.packing_qty}}</span>
                                    <span *ngIf="product.master_packing_qty > 0">, &nbsp; Master Packing Qty : {{product.master_packing_qty}}</span>
                                    <br/>
                                    </span>

                                    <span *ngIf="product.unit != '' && product.unit != ' ' && product.unit != null && product.unit != 'null'">Unit : {{product.unit}}<br/></span>
                                </div>
                                <div class="item-options row m-0 ">

                                    <div class="item-quantity col-12 col-md-6 " (click)="modalOpen(modalBasic,cndx,ndx)">
                                        <h6 class="item-price" *ngIf="product.dispaly_price > 0">₹ {{product.dispaly_price}} <del class="text-danger" *ngIf="product.discount > 0">₹ {{product.sales_price}}</del></h6>
                                        <h6 class="item-price" *ngIf="product.dispaly_price == 0">₹ {{product.sales_price}}</h6>
                                    </div>

                                    <div class="item-quantity col-12 col-md-6 text-right" *ngIf="take_order">
                                        <div class="quantity-counter-wrapper text-right ">
                                            <core-touchspin *ngIf="product.qty > 0" [numberValue]="product.qty" size="lg" [stepValue]="product.min_order_qty" [minValue]="0" [maxValue]="1000 " (onChange)="countChange($event,product.id,ndx,cndx) "></core-touchspin>
                                            <core-touchspin *ngIf="!product.qty" [numberValue]="0" size="lg" [stepValue]="product.min_order_qty" [minValue]="0" [maxValue]="1000 " (onChange)="countChange($event,product.id,ndx,cndx) "></core-touchspin>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--/ Ecommerce Item -->
                    </section>
                </ng-template>
            </ngb-panel>

        </ngb-accordion>


        <div class="mt-5" *ngIf="productsList?.length != 0 && false">
            <!-- E-commerce Products Starts -->
            <div class="ecommerce-application" *ngFor="let products of productsList; let cndx = index">
                <div class="row" *ngIf="products.category_name != null">
                    <div class="col">
                        <div class="card p-0">
                            <div class="card-header p-0" style="border-bottom:2px #174772 solid;">
                                <h4 class="card-title bg-primary p-1 text-white" style="border-radius: 5px 0 0 0;">{{ products.category_name }}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="row match-height">
                    <!-- Ecommerce Item -->
                    <div class="col-6 col-md-3 col-lg-3" style="padding: 0px 10px;" *ngFor="let product of products.products; let ndx = index">
                        <div class="card pt-1 pb-1">
                            <div class="item-img text-center" *ngIf="product.productImage?.length != 0" (click)="modalOpen(modalBasic,cndx,ndx)">

                                <img *ngIf="product.productImage?.length != 0" src="{{product.productImage[0].product_photo}}" alt="Digital Order Book" style="width: 150px;">
                                <img *ngIf="product.productImage?.length == 0" src="assets/images/no-img.png " alt="Digital Order Book " style="width: 150px;opacity: 0.2; ">

                            </div>
                            <div class="card-body " style="padding: 0px 10px; " (click)="modalOpen(modalBasic,cndx,ndx)">

                                <span *ngIf="product.category_name != ''">{{product.category_name}}<br/></span>
                                <h4>{{product.product_code}} {{product.product_name}}</h4>
                                <span *ngIf=" product.size_title != '' || product.color_title != '' ">
                                    <span *ngIf="product.size_title != '' ">Size : {{product.size_title}}</span>
                                <span *ngIf="product.color_title != '' ">, &nbsp; Color : {{product.color_title}}</span>
                                <br/>
                                </span>

                                <span *ngIf="product.packing_qty> 0 || product.master_packing_qty > 0">
                                    <span *ngIf="product.packing_qty > 0">Packing Qty : {{product.packing_qty}}</span>
                                <span *ngIf="product.master_packing_qty > 0">, &nbsp; Master Packing Qty : {{product.master_packing_qty}}</span>
                                <br/>
                                </span>

                                <span *ngIf="product.unit != '' && product.unit != ' ' && product.unit != null && product.unit != 'null'">Unit : {{product.unit}}<br/></span>
                            </div>
                            <div class="item-options row m-0 ">

                                <div class="item-quantity col-12 col-md-6 " (click)="modalOpen(modalBasic,cndx,ndx)">
                                    <h6 class="item-price" *ngIf="product.dispaly_price > 0">₹ {{product.dispaly_price}} <del class="text-danger" *ngIf="product.discount > 0">₹ {{product.sales_price}}</del></h6>
                                    <h6 class="item-price" *ngIf="product.dispaly_price == 0">₹ {{product.sales_price}}</h6>
                                </div>

                                <div class="item-quantity col-12 col-md-6 text-right" *ngIf="take_order">
                                    <div class="quantity-counter-wrapper text-right ">
                                        <core-touchspin *ngIf="product.qty > 0" [numberValue]="product.qty" size="lg" [stepValue]="product.min_order_qty" [minValue]="0" [maxValue]="1000 " (onChange)="countChange($event,product.id,ndx,cndx) "></core-touchspin>
                                        <core-touchspin *ngIf="!product.qty" [numberValue]="0" size="lg" [stepValue]="product.min_order_qty" [minValue]="0" [maxValue]="1000 " (onChange)="countChange($event,product.id,ndx,cndx) "></core-touchspin>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--/ Ecommerce Item -->
                </section>
                <!-- E-commerce Products Ends -->
            </div>
        </div>
        <div class="col text-center " *ngIf="productsList?.length==0 ">
            <div class="loader mt-2 " *ngIf="loading ">
                <img src="assets/images/loder.gif " />
                <p class="card-text ">{{ 'PLEASE_WAIT_LOADING' | translate }}</p>
            </div>
            <div class="unauthorized-access mt-2 " *ngIf="!loading ">
                <img src="assets/images/nodata.png " />
                <p class="card-text ">{{ 'NO_DATA_FOUND' | translate }}</p>
            </div>
        </div>

    </div>
</div>

<a class="btn btn-success btn-icon nav-link place-order" routerLink="/order-place" *ngIf="cartCount > 0">
    <span [data-feather]="'shopping-cart'" [class]="'ficon'"></span>
    <span class="badge badge-pill badge-primary badge-up cart-item-count">{{ cartCount }}</span>
</a>


<!-- Modal -->
<ng-template #modalBasic let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">{{ productDetails.full_name }}</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col" *ngIf="productDetails?.length == 0">
            <div class="text-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">{{ 'LOADING' | translate }}</span>
                </div>
                <div>
                    {{ 'PLEASE_WAIT_SMALL' | translate }}
                </div>
            </div>
        </div>
        <div class="row" *ngIf="productDetails?.length != 0">

            <div class="col-12 p-0 text-center">
                <ngb-carousel *ngIf="productDetails.productImage?.length != 0">
                    <ng-template ngbSlide *ngFor="let proImg of productDetails.productImage">
                        <img src="{{proImg.product_photo_original}}" class="img-fluid" alt="Digital Order Book">
                        <div class="carousel-caption ">
                        </div>
                    </ng-template>
                </ngb-carousel>
                <ngb-carousel *ngIf="productDetails.productImage?.length==0 ">
                    <ng-template ngbSlide>
                        <img src="assets/images/no-img.png " class="img-fluid" alt="Digital Order Book" style="opacity: 0.2; ">
                        <div class="carousel-caption ">

                        </div>
                    </ng-template>

                </ngb-carousel>
            </div>
            <div class="col-12">

                <span *ngIf="productDetails.category_name != ''">{{productDetails.category_name}}<br/></span>
                <span>{{productDetails.product_code}}  {{productDetails.product_name}}</span><br/>
                <span *ngIf=" productDetails.size_title != '' || productDetails.color_title != '' ">
                        <span *ngIf="productDetails.size_title != '' ">Size : {{productDetails.size_title}}</span>
                <span *ngIf="productDetails.color_title != '' ">, &nbsp; Color : {{productDetails.color_title}}</span>
                <br/>
                </span>

                <span *ngIf="productDetails.packing_qty> 0 || productDetails.master_packing_qty > 0">
                        <span *ngIf="productDetails.packing_qty > 0">Packing Qty : {{productDetails.packing_qty}}</span>
                <span *ngIf="productDetails.master_packing_qty > 0">, &nbsp; Master Packing Qty : {{productDetails.master_packing_qty}}</span>
                <br/>
                </span>
                <span *ngIf="productDetails.unit != '' && productDetails.unit != ' ' && productDetails.unit != null && productDetails.unit != 'null'">Unit : {{productDetails.unit}}<br/></span>
                <!-- <h6 class="item-price ">₹ {{productDetails.sales_price}}</h6> -->
                <h6 class="item-price" *ngIf="productDetails.dispaly_price > 0">₹ {{productDetails.dispaly_price}} <del class="text-danger" *ngIf="productDetails.discount > 0">₹ {{productDetails.sales_price}}</del></h6>
                <h6 class="item-price" *ngIf="productDetails.dispaly_price == 0">₹ {{productDetails.sales_price}}</h6>
            </div>

        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')" rippleEffect>
            {{ 'CLOSE' | translate }}
      </button>
    </div>
</ng-template>
<!-- / Modal -->