<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
        <div class="ecommerce-application">
            <div class="" *ngIf="orderHistory?.length != 0">
                <section class="row match-height">

                    <div class="col-12 col-md-3 col-lg-3 p-0-10" *ngFor="let order of orderHistory;let i=index;">
                        <div class="card pt-1 card-border">
                            <div class="card-body p-0-10 text-center">
                                <h4 class="item-name">
                                    #{{ order.invoice_no }}
                                    <a class="pull-right text-danger" *ngIf="order.status == 0" (click)="orderDelete(order.id,i)"><i data-feather="trash"></i></a>
                                </h4>
                                <p class="card-text item-description ">
                                    <b> {{ 'DATE' | translate }} </b>: {{ order.order_date }}
                                </p>
                                <div class="row">
                                    <div class="col-6 p-0 pl-4px">
                                        <a *ngIf="order.status == 0" class="btn btn-warning btn-block br-0 bblr-5px cursor-default" rippleEffect>
                                            {{ 'ORDER_STATUS_PENDING' | translate }}
                                    </a>
                                        <a *ngIf="order.status == 1" class="btn btn-primary btn-block br-0 bblr-5px cursor-default" rippleEffect>
                                            {{ 'ORDER_STATUS_PROCESSING' | translate }}
                                    </a>
                                        <a *ngIf="order.status == 2" class="btn btn-info btn-block br-0 bblr-5px cursor-default" rippleEffect>
                                            {{ 'ORDER_STATUS_HALF_DISPATCH' | translate }}
                                    </a>
                                        <a *ngIf="order.status == 3" class="btn btn-success btn-block br-0 bblr-5px cursor-default" rippleEffect>
                                            {{ 'ORDER_STATUS_FULL_DISPATCH' | translate }}
                                    </a>
                                        <a *ngIf="order.status == 4" class="btn btn-danger btn-block br-0 bblr-5px cursor-default" rippleEffect>
                                            {{ 'ORDER_STATUS_CANCELLED' | translate }}
                                    </a>
                                    </div>
                                    <div class="col-6 p-0 pr-4px">
                                        <a class="btn btn-primary btn-block  br-0 bbrr-5px" (click)="modalOpen(modalBasic,i)" rippleEffec>
                                            <i data-feather="eye" class="mr-25"></i>
                                            <span class="add-to-cart">{{ 'VIEW_DETAIL' | translate }}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
            <div class="col text-center" *ngIf="orderHistory?.length == 0">
                <div class="loader mt-2" *ngIf="loading">
                    <img src="assets/images/loder.gif" />
                    <p class="card-text">{{ 'PLEASE_WAIT_LOADING' | translate }}</p>
                </div>
                <div class="unauthorized-access mt-2" *ngIf="!loading">
                    <img src="assets/images/nodata.png" />
                    <p class="card-text">{{ 'NO_DATA_FOUND' | translate }}</p>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<ng-template #modalBasic let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1" *ngIf="orderdetails?.length != 0">{{ orderdetails.invoice_no }} </h4>
        <h4 class="modal-title" id="myModalLabel1" *ngIf="orderdetails?.length == 0">{{ 'ORDER_DETAILS' | translate }}</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col" *ngIf="orderdetails?.length == 0">
            <div class="text-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">{{ 'LOADING' | translate }}</span>
                </div>
                <div>
                    {{ 'PLEASE_WAIT_SMALL' | translate }}
                </div>
            </div>
        </div>
        <div class="row" *ngIf="orderdetails?.length != 0">
            <div class="col-12 text-right mb-1"><b>{{ 'ORDER_DATE' | translate }}: {{orderdetails.order_date}}</b></div>
            <div class="col-12 p-0 table-reposive overflow-auto">
                <table class="table table-bordered">
                    <tr>
                        <th>{{ 'DESCRIPTION' | translate }}</th>
                        <th>{{ 'RATE' | translate }}</th>
                        <th>{{ 'QTY' | translate }}</th>
                        <th>{{ 'DISPATCH' | translate }}</th>
                        <th>{{ 'REMAINING' | translate }}</th>
                        <th>{{ 'TOTAL' | translate }}</th>
                    </tr>
                    <tr *ngFor="let detail of orderdetails.order_products;">
                        <td>{{ detail.product_code }} {{ detail.product_name }} {{ detail.size_title }} {{ detail.color_title }} </td>
                        <td *ngIf="detail.sales_price == detail.display_price">{{ detail.display_price }}</td>
                        <td *ngIf="detail.sales_price != detail.display_price"><del class="text-danger">{{ detail.sales_price }}</del> / {{ detail.display_price }}</td>
                        <td>{{ detail.order_qty }}</td>
                        <td>{{ detail.dispatch_qty }}</td>
                        <td>{{ detail.remaining_qty }}</td>
                        <th class="text-right">₹ {{ detail.sub_total }}</th>
                    </tr>
                    <tr>
                        <th colspan="5" class="text-right">{{ 'GRAND_TOTAL' | translate }}</th>
                        <th class="text-right">₹ {{ orderdetails.order_amount }}</th>
                    </tr>
                    <tr>
                        <td colspan="6" *ngIf="orderdetails.remarks != ''"><b>{{ 'REMARK' | translate }} :</b> {{ orderdetails.remarks }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')" rippleEffect>
            {{ 'CLOSE' | translate }}
      </button>
    </div>
</ng-template>
<!-- / Modal -->