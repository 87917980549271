import { Component, OnInit } from '@angular/core';
import { CoreConfigService } from '@core/services/config.service';
import { Subject } from 'rxjs';
import { locale as en } from '../i18n/en'
import { locale as fr } from '../i18n/fr'
import { locale as de } from '../i18n/de'
import { CoreTranslationService } from '@core/services/translation.service'
import { ChallansHistoryService } from 'app/main/sample/challan-history/challans-history.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-challan-history',
  templateUrl: './challan-history.component.html',
  styleUrls: ['./challan-history.component.scss']
})
export class ChallanHistoryComponent implements OnInit {

  public coreConfig: any;
  private _unsubscribeAll: Subject<any>;
  public contentHeader: object
  public challanHistory: any = [];
  public challandetails: any;
  public loading: boolean = true;

  constructor(private _router: Router, private _authenticationService: AuthenticationService, private _toastrService: ToastrService, private challansHistoryService: ChallansHistoryService, private _coreTranslationService: CoreTranslationService, private _coreConfigService: CoreConfigService, private modalService: NgbModal) {
    this._unsubscribeAll = new Subject();
    this._coreTranslationService.translate(en, fr, de)

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        menu: {
          hidden: false
        }
      }
    };
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'Delivery Challans',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/'
          },
          {
            name: 'Delivery Challans',
            isLink: false
          }
        ]
      }
    }
    this.loading = true;

    this.getChallanHistory();
  }
  getChallanHistory() {
    this.challansHistoryService.getData().subscribe(
      data => {
        this.loading = false;
        if (data.status) {
          this.challandetails = [];
          this.challanHistory = [];
          this.challanHistory = data.challans;
          this.challandetails = this.challanHistory[0];         
        }
      },
      err => {
        if (err.statusText == 'Unknown Error' || err.error.error == 'Unauthorised Access') {
          this._authenticationService.logout();
          this._router.navigate(['/home']);
        }
      }
    );
  }

  // modal Basic
  modalOpen(modalBasic, key: any) {
    this.challandetails = this.challanHistory[key];
    this.modalService.open(modalBasic, {
      size: 'lg'
    });

  }

}
