<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="faq-search-filter">
            <div class="card faq-search p-3" style="background-image: url('assets/images/bg/banner.png')">
                <div class="card-body text-center">
                    <!-- main title -->
                    <a class="btn btn-primary br-0 bbrr-5px d-none" id="abc" (click)="modalOpen(modalBasic)" rippleEffec>
                        <i data-feather="eye" class="mr-25"></i>
                        <span class="add-to-cart">{{ 'VIEW_DETAIL' | translate }}</span>
                    </a>
                    <h3 class="text-dark">{{ 'WELCOME' | translate }}</h3>
                    <img src="assets/images/logo/logo-text.svg" style="width:250px;" />
                    <div class="loader mt-2" *ngIf="loading">
                        <img src="assets/images/loder.gif" />
                        <p class="card-text">{{ 'PLEASE_WAIT' | translate }}</p>
                    </div>
                    <div class="unauthorized-access mt-2" *ngIf="!loading && !guest">
                        <img src="assets/images/nodata.png" />
                        <p class="card-text">{{ 'UNAUTHORIZED_ACCESS' | translate }}</p>
                    </div>
                    <div class="unauthorized-access mt-2" *ngIf="!loading && guest">
                        <h4>Welcome to {{ supplierName }}</h4>
                        <a class="btn btn-primary br-0 bbrr-5px" id="abcs" (click)="modalOpen(modalBasic)" rippleEffec>
                            <i data-feather="eye" class="mr-25"></i>
                            <span class="add-to-cart">{{ 'PLACE_ORDER' | translate }}</span>
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <!-- account setting page -->
    </div>
</div>

<!-- Modal -->
<ng-template #modalBasic let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">{{ supplierName }}</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" id="close" aria-label="Close">
          <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" ngbAutofocus>
        <div class="row">
            <form name="form" [formGroup]="newForm" (ngSubmit)="onSubmit()" autocomplete="off" *ngIf="save_screen">
                <div class="row">
                    <div class="form-group col-lg-12">
                        <label for="register-phone" class="form-label">{{ 'PHONE_NUMBER' | translate }}<span class="text-danger">*</span></label>
                        <input type="text" formControlName="phone" class="form-control" placeholder="{{ 'PHONE_NUMBER' | translate }}" aria-describedby="register-phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                            <div *ngIf="f.phone.errors.required">{{ 'PHONE_NUMBER_ERROR' | translate }}</div>
                            <div *ngIf="f.phone.errors.pattern">{{ 'PHONE_NUMBER_VALID_ERROR' | translate }}</div>
                        </div>
                    </div>
                    <div class="form-group col-lg-12">
                        <label for="register-enterprise_name" class="form-label">{{ 'ENTERPRISE_NAME' | translate }}<span class="text-danger">*</span></label>
                        <input type="text" formControlName="enterprise_name" class="form-control" placeholder="{{ 'ENTERPRISE_NAME' | translate }}" aria-describedby="register-enterprise_name" [ngClass]="{ 'is-invalid': submitted && f.enterprise_name.errors }" />
                        <div *ngIf="submitted && f.enterprise_name.errors" class="invalid-feedback">
                            <div *ngIf="f.enterprise_name.errors.required">{{ 'ENTERPRISE_NAME_ERROR' | translate }}</div>
                        </div>
                    </div>
                    <div class="form-group col-lg-12">
                        <label for="register-state_id" class="form-label">{{ 'SELECT_STATE' | translate }}<span class="text-danger">*</span></label>
                        <select formControlName="state_id" class="form-control" placeholder="Select State" (change)="getCity($event)" aria-describedby="register-state_id" [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }">
                            <option value="" selected disabled>{{ 'SELECT_STATE' | translate }}</option>
                            <option *ngFor="let state of stateDropDown" [value]="state.id">{{state.state_name}}</option>
                        </select>
                        <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                            <div *ngIf="f.state_id.errors.required">{{ 'SELECT_STATE_ERROR' | translate }}</div>
                        </div>
                    </div>
                    <div class="form-group col-lg-12">
                        <label for="register-city_id" class="form-label">{{ 'SELECT_CITY' | translate }} <span class="text-danger">*</span></label>
                        <select formControlName="city_id" class="form-control" placeholder="Select City" aria-describedby="register-city_id" [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }">
                        <option value="" selected >{{ 'SELECT_CITY' | translate }} </option>
                        <option  *ngFor="let city of cityDropDown" [value]="city.id">
                            {{city.city_name}}</option>
                    </select>
                        <div *ngIf="submitted && f.city_id.errors" class="invalid-feedback">
                            <div *ngIf="f.city_id.errors.required">{{ 'SELECT_CITY_ERROR' | translate }} </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                            <div class="alert-body">
                                <p>{{ error }}</p>
                            </div>
                        </ngb-alert>
                    </div>
                    <div class="col pt-1">
                        <button [disabled]="loadingSave" class="btn btn-primary btn-block" rippleEffect>
                            <span *ngIf="loadingSave" class="spinner-border spinner-border-sm mr-1"></span>{{ 'SUBMIT' | translate }} 
                        </button>
                    </div>
                </div>
            </form>


            <form class="auth-register-form mt-2" [formGroup]="registerotpForm" (ngSubmit)="onSubmitotp()" *ngIf="otp_screen">
                <h2 class="card-title font-weight-bold mb-1 text-center">OTP Verification</h2>
                <p class="card-text mb-2 text-center">Please enter the verification code sent to your number</p>

                <div class="form-group text-center pb-1 pt-3">
                    <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,'otp_two_b')" id="otp_one_a" formControlName="otp_one_a" class="form-control text-center" aria-describedby="register-otp_one_a" tabindex="1" />
                    <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,'otp_three_c')" id="otp_two_b" formControlName="otp_two_b" class="form-control text-center" aria-describedby="register-otp_two_b" tabindex="2" />
                    <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,'otp_four_d')" id="otp_three_c" formControlName="otp_three_c" class="form-control text-center" aria-describedby="register-otp_three_c" tabindex="3" />
                    <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,'otp_five_e')" id="otp_four_d" formControlName="otp_four_d" class="form-control text-center" aria-describedby="register-otp_four_d" tabindex="4" />
                    <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,'otp_six_f')" id="otp_five_e" formControlName="otp_five_e" class="form-control text-center" aria-describedby="register-otp_five_e" tabindex="5" />
                    <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,6)" id="otp_six_f" formControlName="otp_six_f" class="form-control text-center" aria-describedby="register-otp_six_f" tabindex="6" />
                </div>
                <div class="form-group text-center">
                    <p class="text-center">
                        Don't Receive the OTP?
                        <a class="text-primary" style="font-weight: 900;" (click)="resendotp()">Resend OTP</a>
                    </p>
                </div>

                <div class="row">
                    <div class="col-6">
                        <button type="button" class="btn btn-secondary" (click)="backToForm()">Back</button>
                    </div>
                    <div class="col-6">
                        <button [disabled]="loadingOtp" class="btn btn-primary btn-block" style="max-width:300px;" tabindex="6" rippleEffect>
                            <span *ngIf="loadingOtp" class="spinner-border spinner-border-sm mr-1"></span>VERIFY
                        </button></div>
                </div>

                <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                    <div class="alert-body">
                        <p>{{ error }}</p>
                    </div>
                </ngb-alert>
            </form>
        </div>
    </div>
</ng-template>
<!-- / Modal -->