import { Component, OnInit } from '@angular/core'
import { CoreConfigService } from '@core/services/config.service';
import { Subject } from 'rxjs';
import { locale as en } from './i18n/en'
import { locale as fr } from './i18n/fr'
import { locale as de } from './i18n/de'
import { CoreTranslationService } from '@core/services/translation.service'
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Directive, HostListener, ElementRef } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public coreConfig: any;
  public currentUser: any;
  private _unsubscribeAll: Subject<any>;
  public loading: boolean = true;
  public error: any = '';
  public frmerror = '';
  public newForm: FormGroup;
  public submitted = false;
  public unauthorized = false;
  public guest = false;
  public stateDropDown: any;
  public cityDropDown: any;
  public supplierName: string = '';
  public registerotpForm: FormGroup;
  public loadingSave = false;
  public loadingOtp = false;
  public otp_screen = false;
  public otpmsg: any = '';
  public save_screen = true;

  constructor(private el: ElementRef, private _coreTranslationService: CoreTranslationService, private _coreConfigService: CoreConfigService,
    private _route: ActivatedRoute,
    private _router: Router,
    private authenticationService: AuthenticationService, private _toastrService: ToastrService, private modalService: NgbModal, private _formBuilder: FormBuilder) {
    this._unsubscribeAll = new Subject();
    this._coreTranslationService.translate(en, fr, de)

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        menu: {
          hidden: true
        }
      }
    };
  }

  public contentHeader: object

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.authenticationService.logout();

    if (this.authenticationService.getOldCurrentUser() != null) {
      this.authenticationService.autologin();
      //this._router.navigate(['/products']);
    }


    this.loading = true;

    this.newForm = this._formBuilder.group({
      enterprise_name: ['', Validators.required],
      phone: [null, [Validators.required, Validators.pattern('^[0-9]{10}')]],
      state_id: [null, [Validators.required]],
      city_id: [null, [Validators.required]]
    });
    this.f.state_id.setValue('');
    this.f.city_id.setValue('');

    this.registerotpForm = this._formBuilder.group({
      otp_one_a: ['', [Validators.required]],
      otp_two_b: ['', [Validators.required]],
      otp_three_c: ['', [Validators.required]],
      otp_four_d: ['', [Validators.required]],
      otp_five_e: ['', [Validators.required]],
      otp_six_f: ['', [Validators.required]]
    });


    this.getDetails();
  }

  get f() {
    return this.newForm.controls;
  }

  get otp() {
    return this.registerotpForm.controls;
  }

  getDetails() {
    this.guest = false;
    this.authenticationService.logout();
    this._route.queryParams
      .subscribe(params => {
        if (params.key !== undefined) {
          if (params.key === undefined || this.authenticationService.getToken() == "") {
            this.authenticationService.logout();
          }
          else if (this.authenticationService.getToken() != params.key) {
            this.authenticationService.logout();
            this.authenticationService.saveToken(params.key);
            this.getDealerDetails();
          }
          else {
            this.currentUser = this.authenticationService.getcurrentUser();
          }
        }
        else if (params.store !== undefined) {
          this.authenticationService.logout();
          this.authenticationService.saveToken(params.store);
          this.loading = true;
          this.getSupplierDetails(params.store);
        }
        else {
          if (this.authenticationService.getToken() === null || this.authenticationService.getToken() == "") {
            this.authenticationService.logout();
          }
          else {
            this.currentUser = this.authenticationService.getcurrentUser();
            this.authenticationService.currentUser.subscribe(x => (this.currentUser = x));
          }
        }
      });
  }
  getDealerDetails() {
    this.guest = false;

    this.authenticationService.login(this.authenticationService.getToken(), 'customer').subscribe(
      data => {
        this.loading = false;
        if (data.status) {

          this._router.navigate(['/products']);
          document.getElementById("close").click();
        }
        else {
          this.unauthorized = true;
          this.error = data.message;
        }
      });
  }

  // modal Basic
  modalOpen(modalBasic) {
    this.modalService.open(modalBasic, {
      centered: true,
      size: 'sm'
    });

  }

  getSupplierDetails(store) {
    this.authenticationService.login(store, 'guest').subscribe(
      data => {
        this.loading = false;
        if (data.status) {
          this.error = '';
          this.getStateDropdown();
          this.guest = true;
          this.supplierName = data.manufacturer.name;
          document.getElementById("abc").click();
        }
        else {
          this.unauthorized = true;
          this.error = data.message;
        }
      });
  }

  getStateDropdown() {
    this.authenticationService.getState().subscribe(
      data => {
        if (data.status) {
          this.stateDropDown = data.states;
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  getCity(event: any) {
    this.authenticationService.getCity(event.target.value).subscribe(
      data => {
        if (data.status) {
          this.cityDropDown = data.states;
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {

    }
    else {
      this.loadingSave = true;
      const { enterprise_name, phone, state_id, city_id } = this.newForm.controls;
      this.authenticationService.add(enterprise_name.value, phone.value, state_id.value, city_id.value, JSON.parse(this.authenticationService.getToken())).subscribe(
        data => {
          this.loadingSave = false;
          if (data.status) {
            this.error = '';
            this.submitted = false;
            this.save_screen = false;
            this.otp_screen = true;
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
          else {
            if (data.error.phone) {
              this.error = data.error.phone[0];
            }
            else {
              this.error = data.error;
            }
          }

        },
        err => {
          console.log(err);
        });
    }
  }

  onSubmitotp() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerotpForm.invalid) {

    }
    else {
      this.otpmsg = '';
      this.loadingOtp = true;
      this.otpmsg = this.otp.otp_one_a.value + '' + this.otp.otp_two_b.value + '' + this.otp.otp_three_c.value + '' + this.otp.otp_four_d.value + '' + this.otp.otp_five_e.value + '' + this.otp.otp_six_f.value;
      this.authenticationService.verifyotp(this.f.phone.value, this.otpmsg).subscribe(
        data => {
          this.loadingOtp = false;
          if (data.status) {
            this.error = '';
            this._toastrService.success('You Have successfully registered',
              'Congratulations!',
              { toastClass: 'toast ngx-toastr', closeButton: true }
            );
            document.getElementById("close").click();
            this._router.navigate(['/products']);
          }
          else {
            this.error = data.error;
          }
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  resendotp() {
    this.submitted = true;
    this.authenticationService.resendotp(this.f.phone.value).subscribe(
      data => {
        if (data.status) {
          this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
        else {
          this._toastrService.error(data.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        console.log(err);
      }
    );

  }

  nextBox(event: any, next: any) {
    if (event.target.value != "" && event.target.value >= 0 && event.target.value <= 9 && next != "6") {
      const field = document.getElementById(next);
      if (field) {
        field.focus();
      }
    }
  }
  backToForm() {
    this.otp_screen = false;
    this.save_screen = true;
  }

}
