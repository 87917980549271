export const locale = {
  lang: 'en',
  data: {
    MENU: {
      PLACE_ORDER: 'Place Order',
      PRODUCTS: 'Products',
      ORDER_HISTORY: 'Order History',
      DELIVERY_CHALLAN: 'Delivery Challans',
      HELP: 'Help'
    }
  }
}
