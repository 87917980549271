import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  public currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
  public key = localStorage.getItem('currentToken');

  constructor(private _http: HttpClient) { }
  getData(key: any = this.key, type: string = ""): Observable<any> {
    if (this.currentUser.role != 'Client') {
      key = JSON.parse(key);
      type = 'guest';
    }
    else {
      type = '';
    }
    return this._http
      .post(`${environment.apiUrl}get-dealer-products-category-wise`, { key, type })
      .pipe(
        map(data => {
          console.log(this.currentUser.role);
          return data;
        })
      );
  }

}
