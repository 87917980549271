export const locale = {
  lang: 'fr',
  data: {
    TITLE: 'ડિજિટલ ઓર્ડર બુક',
    SORT_INFO: 'ડિજિટલ ઓર્ડર બૂક એક વેબ એપ્લિકેશન છે જે મોબાઇલ એપ્લિકેશન નો ઉપયોગ કર્યા વગર  વેબસાઇટ દ્વારા મેનુફેક્ચરર અને ડીલર્સ ના ઓર્ડર લે છે અને મેનેજ કરે છે. ડિજિટલ ઓર્ડર બૂક એક ઓનલાઈન ઓર્ડર મેનેજમેંટ સિસ્ટમ છે જેનાથી યુઝર સિંગલ ક્લિક કરી ને ઓર્ડર મેનેજ કરી શકે છે.',
    WELCOME: 'આપનું સ્વાગત છે',
    PLEASE_WAIT: 'કૃપા કરીને રાહ જુઓ અમે તમારી વિગતો મેળવી રહ્યા છીએ...',
    UNAUTHORIZED_ACCESS: ' અનઓથોરાઈઝડ ઍક્સેસ. કૃપા કરીને તમારા સપ્લાયરનો સંપર્ક કરો.',
    PLEASE_WAIT_LOADING: 'કૃપા કરીને રાહ જુઓ અમે વિગતો મેળવી રહ્યા છીએ...',
    NO_DATA_FOUND: 'કોઈ ડેટા મળ્યો નથી.',
    REMOVE: 'રિમૂવ',
    REMARK_IF: 'નોંધ, જો કોઈ હોય તો',
    REMARK: 'નોંધ',
    TOTAL_QTY: 'કુલ જથ્થો',
    SUBMIT: 'સબમિટ',
    SEARCH_PRODUCT: 'સર્ચ પ્રોડક્ટ, ન્યૂનતમ 3 અક્ષરો',
    VIEW_DETAIL: 'વિગત જુઓ',
    ORDER_STATUS_PENDING: 'પેન્ડિંગ',
    ORDER_STATUS_PROCESSING: 'પ્રોસેસિંગ',
    ORDER_STATUS_HALF_DISPATCH: 'હાફ ડિસ્પેચ',
    ORDER_STATUS_FULL_DISPATCH: 'ફૂલ ડિસ્પેચ',
    ORDER_STATUS_CANCELLED: 'કેન્સલ',
    DATE: 'તારીખ',    
    ORDER_DATE: 'ઓર્ડર તારીખ',
    ORDER_DETAILS: 'ઓર્ડર ડિટેલ્સ',
    CHALLAN_ID: 'ચલણ નં',
    AMOUNT: 'રકમ',
    CHALLAN_DETAILS: 'ચલન ડિટેલ્સ',
    LOADING: 'લોડ કરી રહ્યું છે...',
    PLEASE_WAIT_SMALL: 'મહેરબાની કરી રાહ જુઓ...',
    DESCRIPTION: 'ડિસ્ક્રિપ્શન',
    RATE: 'ભાવ',
    QTY: 'જથ્થો',
    TOTAL: 'કુલ',
    TRANSPORT_DETAILS: 'પરિવહન ડિટેલ્સ',
    CLOSE: 'બંધ કરો',
    LR_NO: 'એલ. આર. નં',
    TRANSPORT_NAME: 'પરિવહન નું નામ',
    VEHICLE_NO: 'વાહન નં',
    MOBILE: 'મોબાઈલ',
    ORDER_LIST_AVAILABLE_ONCE_YOU: 'ઓર્ડર લિસ્ટ મા પ્રોડક્ટ ઉમેરવાથી ઓર્ડર લિસ્ટ દેખાશે.',
    SUB_TOTAL: 'પેટા ટોટલ',
    DISCOUNT: 'ડિસ્કાઉન્ટ',
    ROUND_OFF: 'વધ ઘટ',
    GRAND_TOTAL: 'ગ્રાન્ડ ટોટલ',
    CLEAR_ALL: 'બધા દૂર કરો',
    PLACE_ORDER: 'ઓર્ડર આપવા માટે ક્લિક કરો',
    PHONE_NUMBER: 'ફોન નંબર',
    PHONE_NUMBER_ERROR: 'ફોન નંબર જરૂરી છે',
    PHONE_NUMBER_VALID_ERROR: 'કૃપા કરીને માન્ય મોબાઈલ નંબર આપો.',
    ENTERPRISE_NAME: 'એન્ટરપ્રાઇઝ નામ',
    ENTERPRISE_NAME_ERROR: 'એન્ટરપ્રાઇઝનું નામ આવશ્યક છે',
    SELECT_STATE: 'રાજ્ય પસંદ કરો',
    SELECT_STATE_ERROR: 'રાજ્ય જરૂરી છે',
    SELECT_CITY: 'શહેર પસંદ કરો',
    SELECT_CITY_ERROR: 'શહેર જરૂરી છે',
    DISPATCH: 'ડિસ્પેચ',
    REMAINING: 'બાકી',
    

  }
}
