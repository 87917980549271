import { Component, OnInit } from '@angular/core';
import { CoreConfigService } from '@core/services/config.service';
import { Subject } from 'rxjs';
import { locale as en } from '../i18n/en'
import { locale as fr } from '../i18n/fr'
import { locale as de } from '../i18n/de'
import { CoreTranslationService } from '@core/services/translation.service'
import { OrdersHistoryService } from 'app/main/sample/order-history/orders-history.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {
  public coreConfig: any;
  private _unsubscribeAll: Subject<any>;
  public contentHeader: object
  public orderHistory: any = [];
  public orderdetails: any;
  public loading: boolean = true;

  constructor(private _router: Router, private _authenticationService: AuthenticationService, private _toastrService: ToastrService, private orderHistoryService: OrdersHistoryService, private _coreTranslationService: CoreTranslationService, private _coreConfigService: CoreConfigService, private modalService: NgbModal) {
    this._unsubscribeAll = new Subject();
    this._coreTranslationService.translate(en, fr, de)
    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        menu: {
          hidden: false
        }
      }
    };
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'Order History',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/'
          },
          {
            name: 'Order History',
            isLink: false
          }
        ]
      }
    }
    this.loading = true;
    this.getOrderHistory();
  }

  getOrderHistory() {
    this.orderHistoryService.getData().subscribe(
      data => {
        this.loading = false;
        if (data.status) {
          this.orderHistory = [];
          this.orderdetails = [];
          this.orderHistory = data.orders;
          this.orderdetails = this.orderHistory[0];

        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/home']);
        }
      }
    );
  }

  // modal Basic
  modalOpen(modalBasic, key: any) {
    this.orderdetails = [];
    for (var i = 0; i < this.orderHistory[key].order_products.length; i++) {
      if (this.orderHistory[key].order_products[i].discount > 0) {
        this.orderHistory[key].order_products[i].display_price = this.orderHistory[key].order_products[i].sales_price - ((this.orderHistory[key].order_products[i].sales_price * this.orderHistory[key].order_products[i].discount) / 100);
      }
      else {
        this.orderHistory[key].order_products[i].display_price = this.orderHistory[key].order_products[i].sales_price;
      }
    }
    this.orderdetails = this.orderHistory[key];

    this.modalService.open(modalBasic, {
      size: 'lg'
    });

  }

  orderDelete(orderId: any, key: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.orderHistoryService.deleteOrder(orderId).subscribe(
          response => {
            if (response.status) {
              me.orderHistory.splice(key, 1);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Your file has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthorised Access') {
              this._authenticationService.logout();
              this._router.navigate(['/home']);
            }
          }
        );
      }
    });
  }

}
