<div class="container-xxl p-0">
    <div class="col pt-3 pr-3 pl-3 bg-primary text-white card">
        <h2 class="text-white">
            {{ 'TITLE' | translate }}
        </h2>
        <p>
            {{ 'SORT_INFO' | translate }}
        </p>
        <p class="text-center">

            <!-- Footer -->

            <span class="d-block d-md-inline-block mt-25">
      COPYRIGHT &copy; {{ year }}
      <a class="ml-25 text-white" href="https://digitalorderbook.com/" target="_blank">Digital Order Book </a>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
            </span>

            <!--/ Footer -->
        </p>
    </div>
</div>

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>