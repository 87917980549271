export const locale = {
  lang: 'de',
  data: {
    TITLE: 'डिजिटल ऑर्डर बुक',
    SORT_INFO: 'डिजिटल ऑर्डर बूक एक वेब एप्लिकेशन है जो मोबाइल एप्लिकेशन का उपयोग किए बिना वैबसाइट से मेनुफ़ेक्चररऔर डीलर्स के ऑर्डर लेती है और मेनेज करती है। डिजिटल ऑर्डर बूक एक ऑनलाइन ऑर्डर मेनेजमेंट सिस्टम है जिससे यूज़र सिंगल क्लिक करके ऑर्डर मेनेज कर सकते है।',
    WELCOME: 'आपका स्वागत है',
    PLEASE_WAIT: 'कृपया प्रतीक्षा करें हम आपकी डिटेल्स प्राप्त कर रहे हैं...',
    UNAUTHORIZED_ACCESS: 'अनओथोराइज्ड एक्सैस । कृपया अपने सप्लायर से संपर्क करें।',
    PLEASE_WAIT_LOADING: 'कृप्या प्रतीक्षा करे हम डिटेल्स प्राप्त कर रहे हैं...',
    NO_DATA_FOUND: 'कोई डेटा नहीं मिला',
    REMOVE: 'रिमूव',
    REMARK_IF: 'टिप्पणी, यदि कोई हो',
    REMARK: 'टिप्पणी',
    TOTAL_QTY: 'कुल मात्रा',
    SUBMIT: 'सबमिट ',
    SEARCH_PRODUCT: 'सर्च प्रॉडक्ट, न्यूनतम 3 वर्ण।',
    VIEW_DETAIL: 'विस्तार से देखें',
    ORDER_STATUS_PENDING: 'पेंडिंग',
    ORDER_STATUS_PROCESSING: 'प्रोसेसिंग',
    ORDER_STATUS_HALF_DISPATCH: 'हाफ डिस्पेच',
    ORDER_STATUS_FULL_DISPATCH: 'फूल डिस्पेच',
    ORDER_STATUS_CANCELLED: 'कैन्सल',
    DATE: 'तारीख',
    ORDER_DATE: 'ऑर्डर की तारीख',
    ORDER_DETAILS: 'ऑर्डर डिटेल्स',
    CHALLAN_ID: 'चालान नंबर',
    AMOUNT: 'रकम',
    CHALLAN_DETAILS: 'चालान डिटेल्स',
    LOADING: 'लोड हो रहा है...',
    PLEASE_WAIT_SMALL: 'कृपया प्रतीक्षा करें...',
    DESCRIPTION: 'डिसक्रिप्शन',
    RATE: 'भाव',
    QTY: 'मात्रा',
    TOTAL: 'टोटल',
    TRANSPORT_DETAILS: 'परिवहन डिटेल्स',
    CLOSE: 'बंद करें',
    LR_NO: 'एल. आर. नं',
    TRANSPORT_NAME: 'परिवहन का नाम',
    VEHICLE_NO: 'वाहन नंबर',
    MOBILE: 'मोबाइल',
    ORDER_LIST_AVAILABLE_ONCE_YOU: 'ऑर्डर लिस्ट मे प्रॉडक्ट एड़ करने से ऑर्डर लिस्ट दिखेगा',
    SUB_TOTAL: 'सब टोटल',
    DISCOUNT: 'डिस्काउंट',
    ROUND_OFF: 'राउंड ऑफ',
    GRAND_TOTAL: 'ग्रांड टोटल',
    CLEAR_ALL: 'सभी हटाएं',
    PLACE_ORDER: 'ऑर्डर देने के लिए क्लिक करें',
    PHONE_NUMBER: 'फ़ोन नंबर',
    PHONE_NUMBER_ERROR: 'फ़ोन नंबर आवश्यक है',
    PHONE_NUMBER_VALID_ERROR: 'कृपया वैध मोबाइल नंबर प्रदान करें।',
    ENTERPRISE_NAME: 'उद्यम का नाम',
    ENTERPRISE_NAME_ERROR: 'उद्यम का नाम आवश्यक है',
    SELECT_STATE: 'राज्य चुनें',
    SELECT_STATE_ERROR: 'राज्य की आवश्यकता है',
    SELECT_CITY: 'शहर चुनें',
    SELECT_CITY_ERROR: 'शहर की आवश्यकता है',
    DISPATCH: 'डिस्पेच',
    REMAINING: 'बचा हुआ',
  }
}
