import { Component, OnInit } from '@angular/core';
import { CoreConfigService } from '@core/services/config.service';
import { Subject } from 'rxjs';
import { locale as en } from '../i18n/en'
import { locale as fr } from '../i18n/fr'
import { locale as de } from '../i18n/de'
import { CoreTranslationService } from '@core/services/translation.service'
import { ProductsService } from 'app/main/sample/products/products.service';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  public coreConfig: any;
  private _unsubscribeAll: Subject<any>;
  public loading: boolean = true;
  public order_details: any = [];
  public order_details_for_count: any = [];
  public cartCount: any = 0;
  public productDetails: any = [];
  public take_order: boolean = true;

  constructor(config: NgbCarouselConfig, private _toastrService: ToastrService, private _router: Router, private _authenticationService: AuthenticationService, private productsService: ProductsService, private _coreTranslationService: CoreTranslationService, private _coreConfigService: CoreConfigService, private modalService: NgbModal) {
    this._unsubscribeAll = new Subject();
    this._coreTranslationService.translate(en, fr, de)

    config.interval = 85000;
    config.wrap = false;
    config.keyboard = false;
    config.pauseOnHover = false;

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        menu: {
          hidden: false
        }
      }
    };
  }
  public contentHeader: object
  public productsList: any = [];
  public page = 1;
  public pageSize = 9;

  ngOnInit() {
    this.order_details = JSON.parse(localStorage.getItem('cart'));
    this.getCartCount();
    this.contentHeader = {
      headerTitle: 'Products',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/'
          },
          {
            name: 'Products',
            isLink: false
          }
        ]
      }
    }
    this.loading = true;
    this.productList();

    if(JSON.parse(localStorage.getItem('currentUser')).manufacturer.order_master_link != null && JSON.parse(localStorage.getItem('currentUser')).manufacturer.order_master_link == 0){
      this.take_order = false;     
    }
  }


  productList() {

    this.productsService.getData().subscribe(
      response => {
        this.loading = false;
        if (response.status) {
          this.productsList = response.data;
          this.order_details = JSON.parse(localStorage.getItem('cart'));
          // if (this.order_details !== null && this.order_details.length > 0) {
          for (var k = 0; k < this.productsList.length; k++) {
            for (var i = 0; i < this.productsList[k].products.length; i++) {

              let dispaly_price = 0;

              if (this.productsList[k].products[i].discount > 0) {
                dispaly_price = this.productsList[k].products[i].sales_price - ((this.productsList[k].products[i].sales_price * this.productsList[k].products[i].discount) / 100);
              }

              this.productsList[k].products[i].dispaly_price = dispaly_price;

              if (this.order_details !== null && this.order_details.length > 0 && this.order_details.some((detail: { id: any; }) => detail.id === this.productsList[k].products[i].id)) {
                for (var j = 0; j < this.order_details.length; j++) {

                  if (this.order_details[j].id == this.productsList[k].products[i].id) {
                    this.productsList[k].products[i].qty = this.order_details[j].qty;
                  }
                }
              }
            }
          }
          //  }

        }
      },
      err => {
        if (err.error.error == 'Unauthorised Access') {
          this._authenticationService.logout();
          this._router.navigate(['/home']);
        }
      }
    );
  }

  countChange(qty: any, product_id: any, index: any, cindex: any) {

    this.order_details = JSON.parse(localStorage.getItem('cart'));

    if (this.order_details !== null && this.order_details.length > 0) {
      if (this.order_details.length > 0 && this.order_details.some((detail: { id: any; }) => detail.id === product_id)) {
        for (var i = 0; i < this.order_details.length; i++) {
          if (this.order_details[i].id == product_id) {
            if (qty == 0) {
              this.order_details.splice(i, 1);
            }
            else {
              this.order_details[i].qty = parseFloat(qty);
              this.order_details[i].total = this.order_details[i].sales_price * parseFloat(qty);
            }
          }
        }
      }
      else {
        let productImg = '';

        if (this.productsList[cindex].products[index].productImage.length > 0) {
          productImg = this.productsList[cindex].products[index].productImage[0].product_photo;
        }
        let dispaly_price = 0;
        if (this.productsList[cindex].products[index].discount > 0) {
          dispaly_price = this.productsList[cindex].products[index].sales_price - ((this.productsList[cindex].products[index].sales_price * this.productsList[cindex].products[index].discount) / 100);
        }
        this.order_details.push({
          'id': this.productsList[cindex].products[index].id,
          'category_name': this.productsList[cindex].products[index].category_name,
          'product_code': this.productsList[cindex].products[index].product_code,
          'product_name': this.productsList[cindex].products[index].product_name,
          'full_name': this.productsList[cindex].products[index].product_name,
          'size_title': this.productsList[cindex].products[index].size_title,
          'color_title': this.productsList[cindex].products[index].color_title,
          'sales_price': this.productsList[cindex].products[index].sales_price,
          'discount': this.productsList[cindex].products[index].discount,
          'product_photo': productImg,
          'dispaly_price': dispaly_price,
          'unit': this.productsList[cindex].products[index].unit,
          'packing_qty': this.productsList[cindex].products[index].packing_qty,
          'master_packing_qty': this.productsList[cindex].products[index].master_packing_qty,
          'min_order_qty': this.productsList[cindex].products[index].min_order_qty,
          'qty': parseFloat(qty),
          'total': this.productsList[cindex].products[index].sales_price * parseFloat(qty),
        });
      }
      localStorage.setItem('cart', JSON.stringify(this.order_details));
    }
    else {
      let productImg = '';
      if (this.productsList[cindex].products[index].productImage.length > 0) {
        productImg = this.productsList[cindex].products[index].productImage[0].product_photo;
      }
      let dispaly_price = 0;
      if (this.productsList[cindex].products[index].discount > 0) {
        dispaly_price = this.productsList[cindex].products[index].sales_price - ((this.productsList[cindex].products[index].sales_price * this.productsList[cindex].products[index].discount) / 100);
      }

      let newPro: any = [];
      newPro.push({
        'id': this.productsList[cindex].products[index].id,
        'category_name': this.productsList[cindex].products[index].category_name,
        'product_code': this.productsList[cindex].products[index].product_code,
        'product_name': this.productsList[cindex].products[index].product_name,
        'full_name': this.productsList[cindex].products[index].product_name,
        'size_title': this.productsList[cindex].products[index].size_title,
        'color_title': this.productsList[cindex].products[index].color_title,
        'sales_price': this.productsList[cindex].products[index].sales_price,
        'discount': this.productsList[cindex].products[index].discount,
        'product_photo': productImg,
        'dispaly_price': dispaly_price,
        'unit': this.productsList[cindex].products[index].unit,
        'packing_qty': this.productsList[cindex].products[index].packing_qty,
        'master_packing_qty': this.productsList[cindex].products[index].master_packing_qty,
        'min_order_qty': this.productsList[cindex].products[index].min_order_qty,
        'qty': parseFloat(qty),
        'total': this.productsList[cindex].products[index].sales_price * parseFloat(qty),
      });
      localStorage.setItem('cart', JSON.stringify(newPro));
    }
    this.getCartCount();
  }

  getCartCount() {
    let newPro: any = [];
    newPro = JSON.parse(localStorage.getItem('cart'));
    if (newPro !== null && newPro.length > 0) {
      this.cartCount = newPro.length;
    }
    else {
      this.cartCount = 0;
    }
  }

  // modal Basic
  modalOpen(modalBasic, key: any, index: any) {
    this.productDetails = this.productsList[key].products[index];
    console.log(this.productDetails);
    this.modalService.open(modalBasic, {
      size: 'md'
    });
  }

}
