import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlaceOrderService {

  public currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
  public key = localStorage.getItem('currentToken');

  constructor(private _http: HttpClient) { }
  getData(searchText: string,key:any = this.key, type: string = ""): Observable<any> {
    if (this.currentUser.role == 'User') {
      key = JSON.parse(key);
      type = 'guest';
    }
    return this._http
      .post(`${environment.apiUrl}get-dealer-products`, { key,searchText,type })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

 storeData(orderData: any,remarks:any,key:any = this.key, type: string = ""): Observable<any> {
  if (this.currentUser.role == 'User') {
    key = JSON.parse(key);
    type = 'guest';
  }
  return this._http
      .post(`${environment.apiUrl}store-order`, { key,orderData,remarks,type })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
